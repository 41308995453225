<template>
  <div class="content f-ajc">
    <!-- 登录容器 -->
    <div class="login">
      <div class="business f-acjsb">
        <div class="logo"><img src="../../assets/sdyj.png" alt=""></div>
        <div class="name">速达易家后台管理系统</div>
      </div>
      <!-- 登录表单 -->
      <a-form-model ref="ruleForm" :model="form" :rules="rules">
        <a-form-model-item ref="name" label="用户名" prop="name">
          <a-input         
            v-model="form.name"
            placeholder="请输入用户名"
            @blur="
              () => {
                $refs.name.onFieldBlur();
              }
            "
          >
            <a-icon slot="prefix" type="user" />
          </a-input>
        </a-form-model-item>
        <a-form-model-item
          ref="password"
          label="密码"
          prop="password"
        >
          <a-input-password
            v-model="form.password"
            placeholder="请输入用户密码"
            @blur="
              () => {
                $refs.password.onFieldBlur();
              }
            "
          />
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 14, offset: 10 }">
          <a-button type="primary" @click="onSubmit"> 登录 </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>

<script>
import {mapState,mapGetters,mapMutations,mapActions} from "vuex";
export default {
  data() {
    return {
      form: {
        name: "",
        password: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "用户名不能为空",
            trigger: "blur",
          },
          // {
          //   min: 3,
          //   max: 5,
          //   message: "长度3-5位4",
          //   trigger: "blur",
          // },
        ],
        password: [
          {
            required: true,
            message: "密码不能为空",
            trigger: "blur",
          },
        ],
      }
    }
  },
  computed: {
  	// 映射数据，方法到this对象上
  	...mapState({
  		login: (state) => state.login.login,
  	}),
  },
  methods: {
	...mapActions({
		  getlogin:"login/getlogin"
	}),
    onSubmit() {
      this.$refs.ruleForm.validate(async(valid) => {
        if (valid) {
			// 获取有效参数
			console.log(valid,this.form.name,this.form.password);
			let payload = {
				username:this.form.name,
				password:this.form.password
			}
			// 同步提交登录请求数据
			await this.getlogin(payload)
			// 存储登录cookie
			console.log(this.login,"登录请求信息");
			// 缓存登录信息
			window.localStorage.setItem('useruserid',this.login.id)
			window.localStorage.setItem('userrole',this.login.role)
			window.localStorage.setItem('userusername',this.login.username)
			window.localStorage.setItem('shopid',this.login.shopid)
			let login = JSON.stringify(this.login)//把对象转化为字符串（stringify）存放进sessionStorage
			 window.localStorage.setItem('login',login)
			 
			// 跳转登录界面
			// this.$router.push({name:"dashboard"})
			this.$router.replace('/dashboard')
			this.$router.go(0)
          
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  // width: 100%;
  background: url(../../assets/bg.svg) no-repeat center;
  height: 100vh;
  .login {
    width: 334px;
    height: 500px;
	.business{
		flex-direction: column;
		.logo{
			width: 100%;
			height: 110px;
			img{
				width: 100%;
				height: 100%;
			}
		}
		.name{
			font-size: 32px;
		}
	}
  }
}
</style>